import React from "react";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";
import { Swiper, SwiperSlide } from "swiper/react";

// CSS
import "../styles.css"
import "swiper/css"

const AboutUs = () => {
   const in_numbers_cards = [
      {
         number: '2019',
         text: 'company was founded',
         date: '-'
      },
      {
         number: '4K+',
         text: 'customers have taken contracts with us',
         date: 'as of October 18th 2022'
      },
      {
         number: '10K+',
         text: 'appliances covered',
         date: 'as of October 18th 2022'
      },
      {
         number: '21.5h',
         text: 'average call out time',
         date: 'as of October 18th 2022'
      },
      {
         number: '25',
         text: 'different appliances to chose from',
         date: 'as of October 18th 2022'
      },
      {
         number: '100+',
         text: 'staff & contractors',
         date: 'as of October 18th 2022'
      },
      {
         number: '24/7',
         text: 'service & helpline',
         date: 'as of October 18th 2022'
      }
   ]

   const senior_leadership_team = [
      {
         name: "Soyfur Rahman",
         title: "Chief Executive Officer",
         linkedin: "soyfur"
      },
      {
         name: "Deva Carlton",
         title: "Chief Commercial Officer",
         linkedin: "devacarlton"
      },
      {
         name: "Luke Mantoura",
         title: "Chief Operational Officer",
         linkedin: "luke-mantoura-32a41732"
      },
      {
         name: "Elliot Waterhouse-Barros",
         title: "Chief Digital Officer",
         linkedin: "ejwaterhouse"
      },
      {
         name: "Martin Slater",
         title: "Operations Manager",
         linkedin: "martin-slater-0b0ab3107"
      }
   ]

   return (
      <React.Fragment>
         <SEO
            title="About us"
            slug="about-us"
            metaDescription="Hi, we're Appliance Sure - Your new favourite appliance cover provider. Learn about who we are and what we do!"
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container guide" style={{backgroundImage: 'url(/images/woman-daughter-oven.jpg)'}}>
            <div className="content-hero-content-container guide">
               <h1>About us</h1>
               <p>Nice to meet you, we're Appliance Sure, the UK's new favourite appliance cover provider. We've been help customers keep their world moving since 2019.</p>
            </div>
         </div>

         <div className="standard-container">
            <div className="content-container narrow-width">
               <Breadcrumbs
                  location="about-us"
               />

               <h2>Who are we?</h2>

               <p>Appliance Sure was founded in July 2019 with the goal of providing the UKs most comprehensive, best quality and best priced appliance cover. We know what it's like for your washing machine, fridge or god forbid, your TV, to stop working. With cover through Appliance Sure we'll have you back up and running within 48 hours. We've since helped 1,000s of people keep their appliances safe and in working order.</p>
            </div>
         </div>

         <div className="standard-container" style={{padding: 0}}>
            <div className="about-us-numbers-outer-container">
               <div className="about-us-numbers-inner-container narrow-width">
                  <h2>In numbers</h2>

                  <br/>
                  <br/>

                  {/* LG / MD devices */}
                  <div className="about-us-numbers-grid-container">
                     {in_numbers_cards.map(card => (
                        <div className="about-us-numbers-card">
                           <span>
                              <h3>{card.number}</h3>
                              <p>{card.text}</p>
                           </span>
                           <p className="about-us-numbers-date">{card.date}</p>
                        </div>
                     ))}
                  </div>

                  {/* SM / XS devices*/}
                  <div id="about-us-numbers-xs">
                     <Swiper
                        loop={true}
                        slidesPerView={3}
                        spaceBetween={20}
                     >
                        {in_numbers_cards.map(card => (
                           <SwiperSlide>
                              <div className="about-us-numbers-card">
                                 <span>
                                    <h3>{card.number}</h3>
                                    <p>{card.text}</p>
                                 </span>
                                 <p className="about-us-numbers-date">{card.date}</p>
                              </div>
                           </SwiperSlide>
                        ))}
                  </Swiper>
                  </div>
               </div>
               
            </div>
         </div>

         <div className="standard-container">
            <div className="content-container narrow-width">
               <h2>Senior leadership team</h2>

               <div className="senior-leadership-team-grid-container">
                  {
                     senior_leadership_team.map(item => (
                        <div className="slt-item">
                           <img src={`/images/headshots/${item.name.replaceAll(" ", "")}.png`} alt={`${item.name} headshot`} />
                           <div className="slt-item-info">
                              <h5>{item.name}</h5>
                              <p>{item.title}</p>
                              
                              <a href={`https://uk.linkedin.com/in/${item.linkedin}`} target="_blank">
                                 <i class="fa-brands fa-linkedin"></i>
                              </a>
                           </div>
                        </div>
                     ))
                  }
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default AboutUs